'use client';

import React, { useState } from 'react';
import FiltersNews from '@/components/NewsComponents/FiltersNews';
import Card from '@/components/News/Card';

export default function ContentStructures({ allNews, socialNetworksContent }) {
  const [filteredNews, setFilteredNews] = useState(allNews);
  const handleFilterNews = (filtered) => {
    setFilteredNews(filtered);
  };

  return (
    <div className="w-full h-full grid grid-cols-1 lg:grid-cols-3 gap-6 fhd:gap-10">
      <div className="w-full grid col-span-1 lg:col-span-2 grid-cols-1 md:grid-cols-2 gap-6 order-2 lg:order-1 place-items-center md:place-items-start">
        {filteredNews &&
          filteredNews.length &&
          filteredNews.map((item, index) => <Card key={index} item={item} />)}
        {!filteredNews.length && (
          <p className="text-start text-customColorWhite">
            Não foi encontrado nenhum conteúdo com esse filtro, tente novamente!
          </p>
        )}
      </div>
      <div className="w-full order-1 lg:order-2">
        <FiltersNews
          allNews={allNews}
          onFilter={handleFilterNews}
          socialNetworksContent={socialNetworksContent}
          searchOnPress
        />{' '}
      </div>
    </div>
  );
}
